import { Box, ButtonBase, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import PopupMultilImage from 'components-v2/mocules/PopupMultilImage';
import { VIDEOS_EXTENSION_FORMATS, settingsMultiImageBoxV2 } from 'constants/data';
import { MISSING_IMAGE } from 'constants/Images';
import useMultiImageBox from 'hooks/useMultiImageBox';
import useToggle from 'hooks/useToggle';
import Image from 'next/image';
import { useState } from 'react';
import Slider from 'react-slick';
import { myLoader } from 'utils';
import { ImageFallbackProductImage } from 'utils/ImageFallback';
import { v4 as uuidv4 } from 'uuid';
import { getLinkProxy } from 'utils/ImageUtils';
import styles from './styles.module.css';

const MultiImageBoxV2 = ({ loading, images = null, videos = [], title = '', isMobile = false, imageName = '', isCombo = false }) => {
  // if combo, just get images
  const medias = isCombo ? images : [...images, ...videos];
  const { selectedImage, handleHoverImage, handleImageSelection, handlePrevious, handleNext } = useMultiImageBox({ images: medias });
  const { open, handleOpen, handleClose } = useToggle();
  const [mainImg, setMainImg] = useState(null);

  const handleOpenImageModal = () => {
    setMainImg(medias[selectedImage]);
    handleOpen();
  };

  function checkIsVideoUrl(url) {
    // return url?.includes('video') || url?.includes('mp4');
    return VIDEOS_EXTENSION_FORMATS.some((format) => url.includes(format));
  }

  return (
    <>
      <div className={styles.container}>
        <div className={clsx(styles.wrapperImg, !isMobile && styles.border_img)}>
          {loading ? (
            <Skeleton variant="rect" classes={{ root: styles.imageMain }} />
          ) : (
            <>
              {images && images.length > 0 ? (
                <ButtonBase onClick={handleOpenImageModal} className="openImg">
                  {checkIsVideoUrl(medias[selectedImage]) ? (
                    <Box>
                      <video width="100%" controls="controls" preload="metadata">
                        <source src={getLinkProxy(medias[selectedImage])} type="video/mp4" />
                      </video>
                    </Box>
                  ) : (
                    <ImageFallbackProductImage
                      width={180}
                      height={180}
                      alt={title}
                      fallbackSrc={medias[selectedImage]}
                      className={styles.imageMain}
                      src={`${open ? mainImg : medias[selectedImage]}`}
                      quality={100}
                      // placeholder="blur"
                      // blurDataURL={`${open ? mainImg : images[selectedImage]}`}
                    />
                  )}
                </ButtonBase>
              ) : (
                <Image width={227} height={227} alt="Image Not Found" loader={myLoader} className={styles.imageNotFound} src={MISSING_IMAGE} />
              )}
            </>
          )}
        </div>
        {!isMobile && (
          <Slider className={styles.slider} {...settingsMultiImageBoxV2}>
            {medias &&
              medias.map((src, index) => (
                <div className={styles.thumbnail} key={uuidv4()}>
                  {loading ? (
                    <Skeleton variant="rect" classes={{ root: styles.thumbnailImage }} />
                  ) : (
                    <ButtonBase onClick={handleOpenImageModal} onMouseOver={() => handleHoverImage(index)}>
                      {checkIsVideoUrl(src) ? (
                        <Grid key={`gr_${src}`}>
                          <video src={getLinkProxy(src)} width={42} height={42} style={{ objectFit: 'contain' }} />
                        </Grid>
                      ) : (
                        <ImageFallbackProductImage
                          width={60}
                          height={60}
                          alt={title}
                          className={clsx(styles.thumbnailImage, {
                            [styles.thumbnailSelected]: index === selectedImage,
                          })}
                          src={`${src}`}
                          fallbackSrc={src}
                          quality={100}
                          // placeholder="blur"
                          // blurDataURL={src}
                        />
                      )}
                    </ButtonBase>
                  )}
                </div>
              ))}
          </Slider>
        )}
        <Typography className={styles.descImg}>* Hình sản phẩm có thể thay đổi theo thời gian</Typography>
      </div>
      {open && (
        <PopupMultilImage
          isMobile={isMobile}
          medias={medias}
          // images={images}
          // videos={videos}
          open={open}
          handleClose={handleClose}
          imageName={imageName}
          selectedImage={selectedImage}
          handleClickImage={handleImageSelection}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
      )}
    </>
  );
};

export default MultiImageBoxV2;
